<template>
  <div
    style="width: 100%; height: 100%; text-align: center; padding-top: 100px"
    v-if="state.loaderEnabled"
  >
    <div class="lds-ring"><img src="media/logos/Logo-Symbole-couleur.svg"/><div></div><div></div><div></div><div></div></div>
  </div>
  <transition name="fade">
    <div class="card" v-if="!state.loaderEnabled">
      <div class="card-header border-0 pt-6">
        <SearchAndFilter
          :listInitial="state.initialMyList"
          :filters="tableFilters"
          @returnSearch="state.myList = $event"
          @returnLoadingDatatable="state.loadingDatatable = $event"
          ExcelFilename="Stillnetwork_Stock"
          :ExcelFormat="ExcelFormat"
        ></SearchAndFilter>
      </div>
      <div class="card-body pt-0">
        <div
          v-if="state.myList.length == 0"
          class="
            alert
            bg-light-primary
            alert-primary
            d-flex
            align-items-center
            p-5
            mb-10
          "
        >
          <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
            <inline-svg src="media/icons/duotune/general/gen048.svg" />
          </span>
          <div class="d-flex flex-column">
            <h4 class="mb-1 text-dark">Aucun équipement de stock</h4>
            <span
              >Il n'existe aucun équipement de stock à afficher correspondant à vos
              critères de recherche.</span
            >
          </div>
        </div>
        <Datatable
          v-if="state.myList && state.myList.length > 0"
          :table-data="state.myList"
          :table-header="tableHeader"
          :enable-items-per-page-dropdown="true"
          empty-table-text="Aucune commande à afficher."
          :key="state.loadingDatatable"
        >
          <template v-slot:cell-loc_reference="{ row: data }">
            <div class="text-gray-800"  style="margin-left: 5px; min-width: 500px;">
              <img :src="`data:image/jpg;base64,${data.soc_logo}`"  v-if="data.soc_logo" alt="image" style="width: auto; max-height: 40px; max-width:75px; background-color: #ececec; float: left;margin-right:10px;" />
              <div v-else class="badge badge-white" style="max-height: 40px; max-width:75px; background-color: #ececec; float: left;margin-right:10px;">{{ data.soc_nom_societe }}</div>
              <div class="text-gray-400">
                <span class="w-150px fs-6 text-dark">{{ data.loc_reference }}</span>
              </div>
              <span class="fs-7 text-gray-500">{{ data.loc_designation }}</span>
              <br>
              <div v-html="data.site" class="badge badge-secondary fs-9 text-gray-700" style="margin-left: 65px;"></div>
            </div>
          </template>
          <template v-slot:cell-qte="{ row: data }">
            <span class="badge badge-white w-200px fs-6 text-gray-900">
            <span v-if="data.qte>0" class="text-dark">{{ data.qte }}</span>
            <span v-else class="text-danger">Indisponible</span>
            </span>
            <br>
            <span v-if="data.reappro>0" class="badge badge-white w-200px fs-6 text-gray-900 mt-1">
              + {{ data.reappro }} 
              <span v-if="data.date_reappro" class="text-gray-400">dés le {{ formatDate(data.date_reappro) }}</span>
              <span v-else class="text-gray-400">(date en attente)</span>
            </span>
          </template>
          <template v-slot:cell-date_liv="{ row: data }">
            <span v-if="data.date_liv" class="text-dark">{{ formatDate(data.date_liv) }}</span>
          </template>
          <template v-slot:cell-toc_code="{ row: data }">
            <template v-for="(item, index) in data.toc_code" :key="index">
              <router-link
                :to="`/commande/${ item.toc_seq }/livraisons`"
                class="btn btn-white text-primary btn-sm mx-1"
              >
                {{ item.code }}
              </router-link>
            </template>         
          </template>
          <template v-slot:cell-histo="{ row: data }">
            <a class="btn btn-sm btn-active-primary mt-2 mb-2 btn-bg-white btn-text-dark" data-bs-toggle="modal" data-bs-target="#kt_modal_2" v-if="data.list_tickets.length >0 " @click="openHisto(data)">
              <span class="svg-icon svg-icon-1">
                <inline-svg src="media/icons/duotune/maps/map009.svg" />
                Historique
              </span>
            </a>
          </template>
        </Datatable>
      </div>
    </div>
  </transition>
  <el-drawer
    v-model="state.drawer"
    title=""
    :with-header="false"
    size="450px"
    modal-class="modal-offre"
  >
    <div class="card shadow-none" style="width: 100%; height: 100%">
      <!--begin::Header-->
      <div class="card-header" id="kt_activities_header">
        <h3 class="card-title fw-bolder text-dark">Détails du stock</h3>
        <div class="card-toolbar">
          <button
            type="button"
            class="btn btn-sm btn-icon btn-active-light-primary me-n5"
            @click="state.drawer = false"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="media/icons/duotune/arrows/arr061.svg" />
            </span>
          </button>
        </div>
      </div>
      <!--end::Header-->

      <!--begin::Body-->
      <div class="card-body position-relative" id="kt_activities_body" style="overflow-y: scroll; height: calc(100vh - 70px);">

        <div class="fs-5 w-100% text-center text-white mt-3 mb-6 p-4" style='background:#003553 !important;  border-radius: 5px'>
          Concerne votre équipement {{ state.mObj.soc_nom_societe }}
        </div>

        <div class="fs-5 mx-3"><strong>{{ state.mObj.loc_reference }}</strong><br /></div>
        <div class="fs-7 mx-3">{{ state.mObj.loc_designation }}</div>
        <br>
        <div class="fs-6 mb-2 text-primary mx-3" v-if="state.mObj.site "><b>{{ state.mObj.site }} :</b></div>
        <div class="fs-6 mb-2 text-primary mx-3" v-else>Stock en cours :</div>
        <div class="fs-5 text-gray-900 mx-3">
          <span v-if="state.mObj.qte>0" class="text-dark">Quantité disponible : <b>{{ state.mObj.qte }}</b></span>
          <span v-else class="text-danger">Aucune disponibilité pour le moment</span>
        </div>
        <div v-if="state.mObj.reappro>0" class="fs-5 text-gray-900 mt-1 mx-3">
          Réapprovisionnement : <b>{{ state.mObj.reappro }}</b>
          <span v-if="state.mObj.date_reappro">&nbsp;dés le {{ formatDate(state.mObj.date_reappro) }}</span>
          <span v-else class="text-gray-600">(date en attente)</span>
        </div>
        <br>

        <div class="fs-5 w-100% text-center text-white mt-8 mb-4 p-4 " style='background:#003553 !important; border-radius: 5px'>
          Historique du stock
        </div>
        <span class="fs-5 text-gray-900 mx-3">
        Initialisation du stock : <span class="text-primary fs-5"><b>{{ state.mObj.total_qte }}</b></span>
        </span>

        <div class="py-5">
          <div class="d-flex flex-column w-100%" style='margin-right: 20px'>
            
            <li
              class="d-flex align-items-center py-2 fs-6 w-100%"
              v-for="item in state.mObj.list_tickets" :key="item.tic_seq"
            >

              <router-link
                :to="`/stilldeploy/ticket/${ item.tic_seq }`"
                class="text-dark"
              >

                <div class="mx-3 pb-4" style="width: 100% !important; border-bottom: 1px solid #0035532e">
                  Ticket <b>#{{ item.tic_seq }}</b> du {{ formatDate(item.tic_d_creation) }} par {{ item.con_prenom }}. {{ item.con_nom }}<br>
                  <i v-if="item.tic_c_label">{{ item.tic_c_label }}<br></i>
                  <span>{{ item.site_adr1 }}<br>{{ item.site_cp }} {{ item.site_ville }}<br></span>
                  Sortie du stock : <span class="text-primary fs-5"><b>{{ item.total }}</b></span>                
                </div>

              </router-link>



            </li>
          </div>
        </div>
      </div>
      <!--end::Body-->
      <!--end::Footer-->
    </div>
  </el-drawer>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, reactive } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as moment from "moment";
import SearchAndFilter from "@/views/still/search_and_filters.vue";

import mAxiosApi from "@/api";

export default defineComponent({
  name: "Liste des commandes en cours",
  components: {
    Datatable,
    SearchAndFilter,
  },
  setup() {
    moment.default.locale("fr");
    const state = reactive({
      loaderEnabled: true,
      myList: [],
      drawer: ref(false),
      sourcePDF: "",
      mObj: {} as any,
      mFact: "",
      initialMyList: [],
      loadingDatatable: 0,
      search: "",
    });
    const tableHeader = ref([
      {
        name: "Equipement",
        key: "loc_reference",
        sortable: true,
      },
      {
        name: "N° PO",
        key: "toc_code",
        sortable: true,
      },
      {
        name: "Qté Dispo / Réappro",
        key: "qte",
        sortable: true,
      },
      {
        name: "Echéance stock",
        key: "date_liv",
        sortable: true,
      },
      {
        name: "Historique",
        key: "histo",
        sortable: true,
      },
    ]);

    const tableFilters = ref([
      {
        name: "Sites",
        key: "site",
        label: "site",
      },
      {
        name: "Réapprovisionnement",
        key: "status_reappro",
        label: "status_reappro",
      },
      {
        name: "Disponibilité",
        key: "status_qte",
        label: "status_qte",
      },
    ]);

    const ExcelFormat = ref({
      "Référence": "loc_reference",
      "Quantité": "qte",  
      "N° PO": "toc_code",
      "Disponible le": "date_liv",
      Site: "site",
    });

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };
    const formatDateAgo = (d) => {
      return moment.default(d, "YYYY-MM-DD").fromNow();
    };

    const formatMoney = (n) => {
      if (!n) n = 0;
      return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    };

    const returnUrlOffre = (data) => {
      return `/stillboard/offres/${data}`;
    };

    const returnUrlCommande = (data) => {
      return `/commande/${data}/livraisons`;
    };

    const formatHoverDateD = (data) => {
      if (!data.date_old_ldc) {
        return `La date de livraison n'a connue aucune variation depuis le ${formatDate(
          data.toc_date_creation
        )} `;
      }
      return `${formatDateAgo(
        data.fraicheur
      )}, la date de livraison est passée du ${formatDate(
        data.date_old_ldc
      )} au ${formatDate(data.date_ldc)}`;
    };

    const affDetail = async () => {
      state.drawer = true;
    };

    onMounted(async () => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Outils de déploiement", []);

      let myList = await getAxios("/getstock");
      state.initialMyList = myList.records;
      state.myList = myList.records;

      myList.records.forEach((el) => {
        el.toc_code = JSON.parse(el.toc_code);
        //console.log(el.list_tickets)
        el.list_tickets = JSON.parse(el.list_tickets);
      })

      //console.log(myList.records);
      state.loaderEnabled = false;
    });

    const openHisto = async (data) => {
      state.mObj = data;
      state.drawer = true;
    };

    return {
      tableHeader,
      state,
      formatDate,
      formatDateAgo,
      formatMoney,
      affDetail,
      formatHoverDateD,
      returnUrlOffre,
      returnUrlCommande,
      tableFilters,
      ExcelFormat,
      openHisto
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  // console.log(response.data);
  return response.data;
}
</script>
